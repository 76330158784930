@value blue, blue600, grey50 from "colors.module.css";

.grid {
  display: grid;
  grid-auto-flow: column;
  gap: 1.5rem;
}

.tile {
  width: 11rem;
  height: 11rem;
  display: grid;
  grid-template-rows: 3rem 1fr;
  justify-items: center;
  background-color: #f7fff5;
  border-radius: 0.55rem;
  transition: all 0.2s ease-in-out;
}

.content {
  display: flex;
  align-items: center;
}

.label {
  color: grey50;
  text-align: center;
  width: 100%;
  background-color: blue;
  font-family: "Flexo", sans-serif;
  font-size: 1.125rem;
}

.tile:hover {
  transform: translateY(-5px);
  cursor: pointer;
}

.tile:hover .label {
  background-color: blue600;
}

.tile:hover .icon {
  fill: blue600;
}

.icon {
  fill: blue;
}
