@value green from "colors.module.css";

.grid {
  display: grid;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}

.inputGroup {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
}

.input {
  text-align: center;
  width: 1rem;
}

.formHelperText {
  font-size: 14px;
}

.fakeLink {
  color: green;
  cursor: pointer;
}
