@value blue from "colors.module.css";

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  justify-content: center;
  width: 100%;
}

.outlined
  :global(.MuiOutlinedInput-root:not(.Mui-error):not(.Mui-disabled):hover
    .MuiOutlinedInput-notchedOutline),
.outlined
  :global(.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline) {
  border-color: blue;
}

.outlined :global(.MuiInputLabel-root.Mui-focused) {
  color: blue;
}

.underlined :global(.MuiInput-root:hover:not(.Mui-disabled):before),
.underlined :global(.MuiInput-root:after) {
  border-color: blue;
}

.underlined :global(.MuiInputLabel-root.Mui-focused) {
  color: blue;
}
