.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}

.button {
  align-self: flex-end;
  margin-bottom: 0.75rem;
}
