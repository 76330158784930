.page {
  width: 100%;
  padding: 0 2.5rem;
}

.bold {
  font-weight: 700;
}

.demoCopy {
  font-size: 14px;
}
