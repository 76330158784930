@value grey400 from "colors.module.css";

.content {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-gap: 1.25rem;
  grid-template-columns: repeat(3, min-content);
  height: 55px;
}

.content svg {
  fill: grey400;
}
