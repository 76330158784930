.root {
  width: 300px;
}

.card {
  width: 200px;
  height: 200px;
}

.media {
  height: 100px;
  margin: 0.25rem 0;
}
