@value grey300 from "colors.module.css";

.grid {
  flex: 1;
}

.palette {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 20%;
  width: 100%;
  margin-bottom: 0;
  border: 1px solid grey300;
}

.palette div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.palette div code {
  display: flex;
  align-items: center;
  justify-content: center;
}
