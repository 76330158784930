@value green, grey from "colors.module.css";

.body {
  width: 75%;
  display: grid;
  grid-template-columns: max-content auto;
  gap: 2rem;
  padding: 2rem 4rem;
}

.body > div:first-child {
  grid-column-start: 1;
  grid-column-end: 3;
}

.chip {
  margin-bottom: 2.5rem;
}

.step {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.5rem 0;
}

.step > svg {
  padding-right: 0.5rem;
  fill: grey;
}

.step > .completed {
  fill: green;
}

.current {
  font-weight: 700;
}

.stepSubText {
  color: grey;
  font-size: 12px;
  padding-left: 32px;
  flex-basis: 100%;
}

.form {
  display: grid;
  grid-auto-flow: row;
  gap: 1rem;
  border-left: 2px solid black;
  padding-left: 4rem;
  width: min-content;
}
