@value green from "colors.module.css";

.sticky {
    position: sticky;
    top: 0;
}

.headerStyling {
    background-color: green;
    color: white;
    padding: 4px;
}

/* used to allow display inside demo box*/
.containDemo {
    height: 200px;
    width: 2000px;
    overflow-y: scroll;
}
