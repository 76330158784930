@value blueLight, blue500, blue100, blue900, darkBlue500 from "colors.module.css";

.textfield :global(.MuiFormLabel-root.Mui-focused) {
  color: blue500;
}

.textfield:global(:not(.Mui-error):not(.Mui-disabled):hover
    .MuiOutlinedInput-notchedOutline) {
  border-color: blue500;
}

.textfield
  :global(.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline) {
  border-color: blue500;
}

.group {
  display: grid;
  grid-template-columns: 40px 1fr;
}

.groupLetter {
  color: blue900;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
}

.divider {
  margin: 8px 16px;
}

.tag {
  background-color: blue100;
  color: darkBlue500;
  font-size: 0.75rem;
  padding: 2px 8px;
  border-radius: 2px;
  cursor: pointer;
}

:global(#css-modules-custom-components-popup .MuiAutocomplete-option) {
  font-size: 0.75rem;
}

/* option loses focus on deselect, so you need to set the hover color */
:global(#css-modules-custom-components-popup .MuiAutocomplete-option:hover),
:global(#css-modules-custom-components-popup
    .MuiAutocomplete-option[data-focus="true"]) {
  background-color: rgba(0, 169, 224, 0.15);
}

:global(#css-modules-custom-components-popup
    .MuiAutocomplete-option[aria-selected="true"]) {
  background-color: rgba(0, 169, 224, 0.38);
}
