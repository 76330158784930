@value grey100, grey500 from "colors.module.css";

.page {
  display: flex;
  align-items: flex-end;
  width: 600px;
  height: 400px;
  background: grey100;
}

.content {
  display: grid;
  grid-template-columns: repeat(3, min-content);
  justify-content: center;
  align-items: center;
  height: 55px;
  grid-gap: 2rem;
}

.content svg {
  fill: grey500;
}

.drawer {
  width: 100%;
  z-index: 1;
}
