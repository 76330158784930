@value green50, green500, textPrimary from "colors.module.css";

.fvPairSection {
  display: flex;
  gap: 16px;
  flex-direction: row;
}

.hidden {
  display: none;
}

.chip {
  background-color: green50;
  color: textPrimary;
}

.chipIcon {
  fill: green500;
}

.sections {
  width: 500px;
}
