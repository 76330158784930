@value green, grey100 from "colors.module.css";

.iconGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 120px);
  gap: 1rem;
  padding-bottom: 24px;
}

.iconImg {
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: grey100;
  border: 4px solid white;
  transition: all 0.1s ease-in-out;
}

.iconImg:hover {
  transform: scale(1.2);
  cursor: pointer;
}

.iconImg svg {
  min-height: 32px;
  min-width: 32px;
  max-height: 40px;
  max-width: 40px;
}

.iconLabel {
  font-size: 0.8rem;
  text-align: center;
  margin: 0;
  padding-top: 0.35rem;
  line-height: 1.25;
}
