@value blue, blue100, textPrimary from "colors.module.css";

.blueIcon {
  fill: blue;
}

.large {
  height: 32px;
  width: 32px;
}

.chip {
  background-color: blue100;
  margin: 0.5rem 0;
  justify-self: flex-start;
  color: textPrimary;
}
