code[class*="language-"],
pre[class*="language-"] {
  color: white;
  background: none;
  text-shadow: 0 1px rgba(0, 0, 0, 0.3);
  font-size: 0.85rem;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  padding: 0;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;

  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;

  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre[class*="language-"]::-moz-selection,
pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection,
code[class*="language-"] ::-moz-selection {
  text-shadow: none;
  background: #bbdefb;
}

pre[class*="language-"]::selection,
pre[class*="language-"] ::selection,
code[class*="language-"]::selection,
code[class*="language-"] ::selection {
  text-shadow: none;
  background: #bbdefb;
}

@media print {
  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none;
  }
}

/* Code blocks */
pre[class*="language-"] {
  padding: 25px;
  margin: 0;
  margin-bottom: 0.5rem;
  overflow: auto;
  border-radius: 0.25rem;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #333;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #90a4ae;
}

.token.punctuation {
  color: #bdbdbd;
}

.namespace {
  opacity: 0.7;
}

.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #e040fb;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #b2ff59;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #ffff00;
}

.token.atrule,
.token.attr-value,
.token.boolean,
.token.keyword {
  color: #4fc3f7;
}

.token.property,
.token.function,
.token.class-name,
.token.tag {
  color: #ff4081;
}

.token.tag .language-javascript,
.token.regex,
.token.important,
.token.variable {
  color: #ff9800;
}

.token.important,
.token.bold {
  font-weight: bold;
}
.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}
