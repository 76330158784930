@value green from "colors.module.css";

.tabs {
  border-radius: 0.25rem 0.25rem 0 0;
}

.tabs :global(.MuiTabs-indicator) {
  background-color: green;
}

.tab {
  color: green;
}

.tab:hover,
.tab:global(.Mui-focus),
.tab:global(.Mui-focusVisible) {
  color: green;
  background-color: rgba(115, 192, 107, 0.15);
}

.tab:active,
.tab:global(.Mui-active) {
  color: green;
}

.tab:global(.Mui-selected) {
  color: green;
}
