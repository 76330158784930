@value grey500, teal, purple50, teal500, teal300, midnightBlue300, midnightBlue50, midnightBlue300 from "colors.module.css";

.clear :global(.MuiSwitch-switchBase:not(.Mui-checked)) {
  color: grey500;
}

.heart :global(.MuiSwitch-switchBase) {
  color: teal500;
}

.heart :global(.MuiSwitch-switchBase):hover {
  background-color: rgba(58, 204, 241, 0.15);
}

.heart :global(:not(.Mui-checked) + .MuiSwitch-track) {
  background-color: teal50;
}

.heart :global(.Mui-checked + .MuiSwitch-track) {
  background-color: teal300;
}

.star :global(.MuiSwitch-switchBase) {
  color: midnightBlue300;
}

.star :global(.MuiSwitch-switchBase):hover {
  background-color: rgba(58, 101, 241, 0.15);
}

.star :global(:not(.Mui-checked) + .MuiSwitch-track) {
  background-color: midnightBlue50;
}

.star :global(.Mui-checked + .MuiSwitch-track) {
  background-color: midnightBlue300;
}
