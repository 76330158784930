@value blue, blueDark from "colors.module.css";

.icon {
  margin-right: 0.5rem;
}

.blueButton {
  background-color: blue;
}

.blueButton:hover {
  background: #00a1dc;
}

.blueButton:active,
.blueButton:global(.Mui-active) {
  background-color: blueDark;
}
