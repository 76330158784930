.colors {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.color {
  display: grid;
  grid-auto-rows: 1fr auto;
}

.status {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.15rem;
  align-items: center;
}
