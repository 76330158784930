@value grey100 from "colors.module.css";
@value blockPadding, blockBorderRadius from "./Page.module.css";

.wrapper {
  margin-bottom: 32px;
}

.header {
  display: flex;
  justify-content: flex-end;
}

.header > div {
  padding: 0.25rem;
}

.body {
  display: flex;
  flex: 1 1 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: blockPadding;
  background-color: white;
  border: 0.5rem solid grey100;
  border-radius: blockBorderRadius;
  overflow: auto;
}

.bodyLeft {
  display: block;
}

.dark {
  background-color: grey100;
}
