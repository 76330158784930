@value green, grey100 from "colors.module.css";

.app {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 3rem;
}

.logo svg {
  padding: 0 10px 5px 0;
  width: 190px;
}

.spacingHack {
  letter-spacing: -4px;
}

.logo h1 {
  font-size: 4.5rem;
  font-weight: 100;
  letter-spacing: 0;
  margin-top: 0.5rem;
}

.logo h4 {
  margin: 5px;
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 1rem;
}

.card {
  display: grid;
  gap: 0.5rem;
  grid-template-rows: auto 0.55fr 2fr auto;
  flex: 1 0 300px;
  justify-content: center;
  padding: 1rem 1.5rem;
  max-width: 450px;
  background-color: grey100;
}

.card h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.card h3 svg {
  fill: green;
  height: 1.5rem;
  width: 1.5rem;
  vertical-align: baseline;
  padding-right: 0.25rem;
}

.card p {
  font-size: 0.85rem;
  margin: 0;
}

.codeChunk pre {
  margin: 0;
  padding: 0 1rem;
}

.codeChunk pre code {
  font-size: 0.75rem;
}
