@value blue from "colors.module.css";

.card {
  max-width: 350px;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.card > :global(.MuiCardContent-root) {
  flex: 1;
  margin: 10px 0;
}

.avatar {
  background-color: blue;
}
