@value green, greyLight, greyMain from "colors.module.css";

.menu {
  width: 15vw;
  min-width: 220px;
}

.menuHeader {
  padding: 1rem;
}

.menu h1 {
  margin: 0;
}

.menu :global(.Mui-selected) {
  background-color: white;
}

.menu :global(.Mui-selected .MuiListItemText-root) {
  color: green;
  font-weight: 600;
}

.menu :global(.MuiListItem-root):not(.inset) {
  padding: 4px 16px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.scrollable {
  overflow: auto;
  /* replicates padding without allowing inset items to show through while scrolling */
  border-top: 8px solid white;
}

.inset {
  font-size: 0.85rem;
  padding-left: 16px;
}
