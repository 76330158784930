@value green600, greyLight from "colors.module.css";

.filters {
  padding-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tag {
  height: 32px;
  border-radius: 2px;
  background-color: greyLight;
}

.search {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  grid-gap: 0.7rem;
}
