.container {
  display: flex;
}

.demoButton {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

