@value danger, dangerLight from "colors.module.css";

.flex {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.switch :global(.MuiSwitch-switchBase:not(.Mui-checked)) {
  color: danger;
}

.switch :global(.MuiSwitch-switchBase:not(.Mui-checked)):hover {
  background-color: rgba(241, 58, 66, 0.15);
}

.switch :global(.MuiSwitch-switchBase:not(.Mui-checked) + .MuiSwitch-track) {
  background-color: dangerLight;
}
