@value grey from "colors.module.css";

.grid {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  gap: 4rem;
  align-items: center;
  padding-top: 15vh;
  overflow: hidden;
}

.backgroundCircle {
  justify-self: right;
  background-image: linear-gradient(
    to right top,
    #a3d199,
    #98d9bd,
    #a1ded9,
    #bae1e9,
    #d5e3ec
  );
  height: 300px;
  width: 300px;
  border-radius: 50%;
  display: flex;
  align-items: center;
}

.info {
  justify-self: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.errorCode,
.subtitle,
.message {
  margin-top: 0;
}

.errorCode {
  color: #a3d199;
  font-size: 10rem;
  font-weight: 200;
  line-height: 1;
}

.subtitle {
  color: black;
}

.message {
  color: grey;
}

.buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
