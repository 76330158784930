@value greenLight from "colors.module.css";

.card {
  width: 350px;
}

.media {
  height: 250px;
  background-color: greenLight;
}
