@value grey900 from "colors.module.css";

.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  height: 100%;
  gap: 0.2rem;
  place-items: center;
}

.grid svg {
  fill: grey900;
  width: 1.5rem;
  height: 1.5rem;
  transition: all 0.1s ease-in-out;
}

.grid svg:hover {
  transform: scale(1.5);
  cursor: pointer;
}

.icon {
  width: 3rem;
}
