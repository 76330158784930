.grid {
  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  justify-content: center;
  width: 100%;
}

.grid > div:last-child {
  grid-column: 1 / -1;
}
