.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    ".  t  . "
    "l  .  r "
    ".  b  . ";
  gap: 0.2rem;
}

.t {
  grid-area: t;
}
.l {
  grid-area: l;
}
.r {
  grid-area: r;
}
.b {
  grid-area: b;
}

.button {
  height: 50px;
  width: 120px;
}
