@value grey200 from "colors.module.css";

.tab {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden {
  display: none;
}

.icon {
  fill: grey200;
  width: 250px;
  height: 250px;
  padding: 2rem;
}
