.square {
  height: 100px;
  transition: all 0.1s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.square:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transform: scale(1.1);
  cursor: pointer;
}

.code {
  background-color: transparent;
  font-size: 0.7rem;
  padding: 0.5rem;
}
