@value grey500, danger, danger50, danger100, danger300 from "colors.module.css";

.clear :global(.MuiSwitch-switchBase:not(.Mui-checked)) {
  color: grey500;
}

.heart :global(.MuiSwitch-switchBase) {
  color: danger100;
}

.heart :global(.MuiSwitch-switchBase):hover {
  background-color: rgba(241, 58, 66, 0.15);
}

.heart :global(:not(.Mui-checked) + .MuiSwitch-track) {
  background-color: danger50;
}

.heart :global(.MuiSwitch-switchBase.Mui-checked) {
  color: danger;
}

.heart :global(.Mui-checked + .MuiSwitch-track) {
  background-color: danger300;
}
