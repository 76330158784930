@value danger from "colors.module.css";

.heart svg {
  color: danger;
}

.heart:hover,
.heart:global(.Mui-checked):hover {
  background-color: rgba(241, 58, 66, 0.15);
}
