@value textPrimary, blue100, blue200 from "colors.module.css";

.root {
  display: grid;
  grid-auto-flow: row;
  gap: 0.25rem;
  width: 100%;
}

.display {
  width: 100%;
}

.code {
  background-color: blue100;
}

.root > * {
  color: textPrimary;
}

.chip {
  background-color: blue100;
  margin: 0.5rem 0;
  justify-self: flex-start;
  color: textPrimary;
}

.alignment > p {
  border: 1px solid blue200;
  padding: 0.25rem;
}

.color {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2.5rem;
}
