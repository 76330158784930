@value greyDark, textPrimary, warning50, warning300, blue50, blue300, danger50, danger300, blue400, blue500 from "colors.module.css";

@value blockPadding: 25px;
@value blockBorderRadius: 0.25rem;

.grid {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  justify-content: center;
}

.fullWidth {
  width: 100%;
}

.subtitle {
  color: greyDark;
}

.titleIcon {
  fill: blue400;
}

.supporting {
  padding-bottom: 1.5rem;
}

.codeHeading {
  font-size: 1.25rem;
}

.blackCode {
  color: textPrimary;
}

.anchor {
  visibility: hidden;
  position: absolute;
  margin-top: -75px;
}

.markdown *:first-child {
  margin-top: 0;
}

.markdown *:last-child {
  margin-bottom: 0;
}

.panel {
  display: grid;
  grid-template-columns: min-content auto;
  gap: 1.5rem;
  justify-content: flex-start;
  align-items: center;
  padding: blockPadding;
  border-radius: blockBorderRadius;
  margin-bottom: 2rem;
}

.panel * {
  margin: 0;
}

.panel *:not(:first-child):not(:nth-child(2)) {
  grid-column-start: 2;
  grid-column-end: 2;
}

.warning {
  background-color: warning50;
  border-left: 4px solid warning300;
}

.info {
  background-color: blue50;
  border-left: 4px solid blue300;
}

.danger {
  background-color: danger50;
  border-left: 4px solid danger300;
}

.panel a {
  color: blue500;
}

.sectionLink {
  display: flex;
  gap: 6px;
  align-items: flex-start;
  scroll-margin-top: 75px;
  padding-top: 24px;
}

.sectionLink .button {
  display: none;
}

.sectionLink:hover .button {
  display: inline-block;
}
