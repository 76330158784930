@value textSecondary from "colors.module.css";

/* card example */

.card {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr auto;
}

.robot {
  display: flex;
  border-radius: 4px 0 0 4px;
  background-image: linear-gradient(
    to right top,
    #a3d199,
    #98d9bd,
    #a1ded9,
    #bae1e9,
    #d5e3ec
  );
}

.robot > svg {
  height: 200px;
  width: 200px;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 1.5rem;
}

.text > div:nth-child(1) {
  color: textSecondary;
  font-size: 12px;
  text-align: right;
  line-height: 1;
}

.text > div:nth-child(2) {
  font-weight: 600;
  font-size: 1.5rem;
}

.text > div:nth-child(4) {
  color: textSecondary;
}

.buttonGroup {
  display: grid;
  grid-auto-flow: column;
  gap: 2rem;
}

/* dialog example */

.paper {
  padding: 1.5rem;
  display: grid;
  grid-auto-flow: row;
  gap: 1rem;
}

.dialogButton {
  display: flex;
  place-items: center;
}
