@value blue600, green700, teal800 from "colors.module.css";

.root {
  display: flex;
  flex-direction: column;
}

.blue {
  color: blue600;
}

.green {
  color: green700;
}

.teal {
  color: teal800;
}
