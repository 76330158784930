@value textSecondary from "colors.module.css";

.page {
  display: flex;
  width: 600px;
  height: 600px;
}

.logo {
  height: 26px;
  width: 26px;
}

.pageDemo {
  flex: 1 1;
  overflow-y: scroll;
  padding: 0 30px;
}

.pageDemo h1 {
  color: textSecondary;
}

/*
  This is custom styling to position the nav in the demo within its container,
  and not fixed on the whole page
*/
.relativeToDemo {
  position: relative;
}
